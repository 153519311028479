<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <button class="close-button" @click="closeModal">&times;</button>
      <h2>Emergency Services Information for Ireland</h2>
      <h4 style="color: red">
        In case of an emergency, dial <strong>112</strong> or
        <strong>999</strong>. These numbers are free to call and connect you to
        the following services: <br /><b
          >MOUNTAIN RESCUE, COASTGUARD, AMBULANCE, POLICE and FIRE BRIGADE</b
        >
      </h4>
      <h3>Non-Urgent Assistance</h3>
      <p>
        For non-urgent assistance, contact your local Garda station. You can
        find contact details for stations on the
        <a href="https://www.garda.ie/en/contact-us/" target="_blank"
          >Garda Website</a
        >.
      </p>

      <h3>Emergency Preparedness</h3>
      <p>
        Ensure you have access to key emergency contacts, first aid supplies,
        and knowledge of evacuation routes in your area.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px; /* Padding for small screens */
  overflow-y: auto; /* Enable scrolling for tall content */
}

.modal-content {
  background: #f8f9fa; /* Light background for contrast */
  color: #333; /* Dark text for readability */
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  width: 100%; /* Adjust for smaller screens */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333; /* Match text color */
}

h2 {
  margin-top: 0;
  font-size: 1.2rem;
}

h3 {
  font-size: 1.2rem;
  margin-top: 0px;
}

p,
ul {
  font-size: 1rem;
  margin: 5px 0;
}

ul {
  padding-left: 20px;
  list-style: disc;
}
li {
  font-size: 10px;
}
a {
  color: #007bff;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

@media (max-width: 768px) {
  .modal-content {
    font-size: 0.7rem;
    padding: 5px;
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1rem;
  }
}
</style>
