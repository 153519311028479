import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import MainLayout from "../layouts/MainLayout.vue";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import WalksView from "../views/WalksView.vue";
import POIsView from "../views/POIsView.vue";
import ServicesView from "../views/ServicesView.vue";
import WalkDetailsView from "../views/WalkDetailView.vue";
import ElevationView from "../views/ElevationView.vue";
import GalleryView from "../views/GalleryView.vue";
import InformationView from "../views/InformationView.vue";
import PoiServiceDetail from "../views/PoiServiceDetail.vue";
import MapPage from "../views/MapView.vue";
import ReviewsView from "../views/ReviewsView.vue";
import CesiumMapView from "../views/CesiumMapView.vue";
import VideoView from "../views/VideoOverView.vue";
import ReportIssueView from "../views/ReportIssueView.vue";
import RegisterView from "../views/RegisterView.vue";
import CompletedWalks from "../views/CompletedWalksView.vue";
import BlogPage from "../views/BlogPage.vue";

const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      { path: "", name: "home", component: HomeView },
      { path: "about", component: AboutView },
      { path: "walks", component: WalksView, name: "walks" },
      { path: "pois/:CardId", component: POIsView, props: true, name: "pois" },
      {
        path: "services/:CardId",
        component: ServicesView,
        props: true,
        name: "services",
      },
      { path: "walk/:CardId", component: WalkDetailsView },
      { path: "elevation/:CardId", component: ElevationView },
      { path: "gallery/:CardId", component: GalleryView },
      { path: "information/:CardId", component: InformationView },
      { path: "point/:Id", component: PoiServiceDetail },
      { path: "map/:Id", component: MapPage },
      { path: "reviews/:CardId", component: ReviewsView, props: true },
      { path: "cesium-map/:CardId", component: CesiumMapView, props: true },
      { path: "video/:CardId", component: VideoView, props: true },
      { path: "issue/:CardId", component: ReportIssueView, props: true },
      {
        path: "register/:CardId",
        name: "register",
        component: RegisterView,
        props: true,
      },
      { path: "completedWalks", component: CompletedWalks },
      {
        path: "/blog/:id",
        name: "blog",
        component: BlogPage,
        props: true, // Allows passing the `id` as a prop
      },
    ],
  },
  // other routes...
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ top: 0 });
      }, 0);
    });
  },
});

router.beforeEach(async (to, from, next) => {
  const dataLoaded = store.getters.isDataLoaded;
  if (!dataLoaded) {
    await store.dispatch("loadData");
  }
  next();
});

export default router;
