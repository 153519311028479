<template>
  <section class="full-width-image-section completed-trails gray-bg">
    <div class="container">
      <div class="text-center">
        <h1 class="trails-header special-header">Completed Trails</h1>
      </div>
      <div class="info-box">
        <p v-if="isRegistered">
          Your email <strong>{{ userEmail }}</strong> is registered for badges.
        </p>
        <p v-else>
          Your email is not registered for badges.
          <router-link to="/register/0">Register here</router-link>.
        </p>
        <p>
          This system tracks your walks and awards badges based on your
          activities. Make sure to register your email to start earning badges
          and enjoy the rewards!
        </p>
      </div>

      <Section>
        <div class="badge-gallery">
          <!-- Milestone Badge -->
          <div v-if="badges.milestone" class="badge earned">
            <img
              :src="`/assets/badges/${badges.milestone}.jpg`"
              :alt="`${badges.milestone} Walks Badge`"
            />
          </div>

          <!-- Completion Badges -->
          <div v-if="badges.easy" class="badge earned">
            <img src="/assets/badges/easy.jpg" alt="All Easy Walks Badge" />
          </div>
          <div v-if="badges.moderate" class="badge earned">
            <img
              src="/assets/badges/moderate.jpg"
              alt="All Moderate Walks Badge"
            />
          </div>
          <div v-if="badges.strenuous" class="badge earned">
            <img
              src="/assets/badges/strenuous.jpg"
              alt="All Strenuous Walks Badge"
            />
          </div>

          <!-- Special Badge -->
          <div v-if="badges.all" class="badge special-badge earned">
            <img src="/assets/badges/all.jpg" alt="All Walks Badge" />
          </div>
        </div>
      </Section>

      <div class="completed-trails-table" v-if="walks.length">
        <div class="completed-trails-head">
          <div class="completed-trails-col"></div>
          <div class="completed-trails-col">Trail</div>
          <div class="completed-trails-col">Completed</div>
        </div>
        <div
          class="completed-trails-body"
          v-for="walk in walks"
          :key="walk.DateTime"
        >
          <div class="completed-trails-col">
            <img src="/assets/img/completed.svg" />
          </div>
          <div class="completed-trails-col" style="text-align: left">
            {{ walk.Name }}
          </div>
          <div class="completed-trails-col-grade">{{ walk.Grading }}</div>
          <div class="completed-trails-col">
            {{ formatDate(walk.DateTime) }}
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="summary-section" hidden>
    <h3>Walk Summary</h3>
    <p>Easy Walks: {{ easyCount }}</p>
    <p>Moderate Walks: {{ moderateCount }}</p>
    <p>Strenuous Walks: {{ strenuousCount }}</p>
  </div>

  <section class="signup-section pt-5 pb-5">
    <div class="container">
      <div class="row g-0">
        <!-- First Column: Image with Text -->
        <div
          class="col-md-6 d-flex flex-column justify-content-center signup-image-column text-white p-5 mt-5 mb-lg-5"
        >
          <h2 class="signup-header">Wicklow Trails</h2>
          <p class="signup-text">
            Sign up for our newsletter to stay updated on our walks, trails, and
            more!
          </p>
        </div>

        <!-- Second Column: Sign Up Form -->
        <div class="col-md-6 bg-white">
          <h2 class="signup-form-header text-white p-3 text-center">Sign Up</h2>
          <div class="px-5 pt-3 pb-5 pt-md-5">
            <form>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your name"
                />
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Your email"
                />
              </div>
              <div class="mb-3">
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Message"
                ></textarea>
              </div>
              <button type="submit" class="btn w-100">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="full-width-image-section footer green-sep"></section>
</template>

<script>
export default {
  name: "CompletedWalks",
  data() {
    return {
      walks: [],
      easyCount: 0,
      moderateCount: 0,
      strenuousCount: 0,
      totalCount: 0,
      badges: {
        milestone: null, // 5, 10, 20, or 40 walks
        easy: false,
        moderate: false,
        strenuous: false,
        all: false,
      },
      userEmail: "",
      isRegistered: false,
    };
  },
  created() {
    this.userEmail = localStorage.getItem("userEmail") || "";
    this.isRegistered = !!this.userEmail;
    this.fetchCompletedWalks();
  },
  methods: {
    async fetchCompletedWalks() {
      try {
        const customerId = localStorage.getItem("tenantId"); // Replace with the actual CustomerId
        const base64Credentials = btoa("Ian:Ennistymon1!");

        const response = await fetch(
          `https://live-api-v2.waywyser.com/api/VisitorCard/GetCompletedWalks?userId=${this.userEmail}&customerId=${customerId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              CustomerId: customerId,
              Authorization: `Basic ${base64Credentials}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch completed walks.");
        }

        //const responseText = await response.text();
        const data = await response.json();

        // Update data properties with the API response
        this.walks = data.Walks || [];
        this.easyCount = data.EasyWalksCount || 0;
        this.moderateCount = data.ModerateWalksCount || 0;
        this.strenuousCount = data.StrenuousWalksCount || 0;

        // Calculate badges
        this.calculateBadges(data);
      } catch (error) {
        console.error(error);
      }
    },
    calculateBadges(data) {
      // Count the number of completed easy, moderate, and strenuous walks
      const easyWalks = this.walks.filter(
        walk => walk.Grading.toUpperCase() === "EASY"
      ).length;
      const moderateWalks = this.walks.filter(
        walk => walk.Grading.toUpperCase() === "MODERATE"
      ).length;
      const strenuousWalks = this.walks.filter(
        walk => walk.Grading.toUpperCase() === "STRENUOUS"
      ).length;

      // Determine the highest milestone badge the user qualifies for
      this.totalCount =
        Number(easyWalks) + Number(moderateWalks) + Number(strenuousWalks);
      if (this.totalCount >= 40) {
        this.badges.milestone = 40;
      } else if (this.totalCount >= 20) {
        this.badges.milestone = 20;
      } else if (this.totalCount >= 10) {
        this.badges.milestone = 10;
      } else if (this.totalCount >= 5) {
        this.badges.milestone = 5;
      } else {
        this.badges.milestone = null;
      }

      // Completion badges
      this.badges.easy = Number(easyWalks) >= Number(data.EasyWalksCount);
      console.log(
        "Easy Walks:",
        easyWalks,
        "Total Easy Walks:",
        data.EasyWalksCount
      );

      this.badges.moderate =
        Number(moderateWalks) >= Number(data.ModerateWalksCount);
      console.log(
        "Mod Walks:",
        moderateWalks,
        "Total Mod Walks:",
        data.ModerateWalksCount
      );

      this.badges.strenuous =
        Number(strenuousWalks) >= Number(data.StrenuousWalksCount);
      console.log(
        "Strn Walks:",
        strenuousWalks,
        "Total Strn Walks:",
        data.StrenuousWalksCount
      );

      this.badges.all =
        Number(easyWalks) >= Number(data.EasyWalksCount) &&
        Number(moderateWalks) >= Number(data.ModerateWalksCount) &&
        Number(strenuousWalks) >= Number(data.StrenuousWalksCount);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return isNaN(date) ? "Invalid Date" : date.toLocaleDateString();
    },
  },
};
</script>

<style scoped>
.completed-walks {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.info-box,
.table-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th,
td {
  border: 1px solid #ccc;
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}

td {
  background-color: #ffffff;
}

h1,
h2 {
  text-align: center;
  color: #333;
}

p {
  text-align: center;
  color: #666;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.badge-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.badge {
  width: 250px; /* Adjust the size as needed */
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease; /* Optional for hover effects */
}

.badge img {
  width: 100%;
  height: auto;
}

.badge:hover {
  transform: scale(1.1); /* Optional hover effect */
}

.special-badge {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
</style>
