<template>
  <!-- INSTALL POPUP -->
  <div>
    <div v-if="showInstallPopup" class="install-popup">
      <p>
        To install this as an app on your device, click below OR click the icon
        on your browser nav bar:
      </p>
      <button
        @click="promptForInstall"
        v-if="deferredPrompt"
        class="install-button"
      >
        Install App
      </button>
      <p v-else>
        Click the install icon (plus symbol) in your browser's address bar to
        install the app.
      </p>
      <button
        @click="dismissInstallPopup"
        class="close-popup-button"
        style="margin-left: 10px"
      >
        Hide
      </button>
    </div>
  </div>
  <!-- END INSTALL POPUP -->

  <section class="image-section">
    <div class="image-container">
      <!-- Background Video -->
      <video autoplay muted loop playsinline class="background-video">
        <source src="/assets/videos/sample2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <!-- Buttons -->
      <div class="button-group d-flex justify-content-center desktop">
        <router-link to="/walks" class="btn mx-2 mt-4 mt-sm-0">
          Discover Trails
        </router-link>
        <router-link to="/completedWalks" class="btn mx-2 mt-4 mt-sm-0">
          Completed Trails
        </router-link>
      </div>

      <div class="button-group d-flex justify-content-center desktop">
        <router-link to="/walks" class="btn mx-2 mt-4 mt-sm-0">
          Discover Trails
        </router-link>
        <router-link to="/completedWalks" class="btn mx-2 mt-4 mt-sm-0">
          Completed Trails
        </router-link>
      </div>
    </div>
  </section>

  <section class="full-width-image-section adventure white-sep">
    <div class="container text-center">
      <h1 class="adventure-header special-header">Adventure Awaits</h1>
      <p class="adventure-subtext">
        Welcome to Wicklow Trails, your ultimate guide to exploring the
        breathtaking landscapes of County Wicklow. Whether you're a seasoned
        hiker or just looking for a peaceful stroll, our app offers a wide range
        of trails suited for every experience level.
      </p>
    </div>

    <div class="button-group d-flex justify-content-center mobile mt-4">
      <router-link to="/walks" class="btn mx-2 mt-4 mt-sm-0">
        Discover Trails
      </router-link>
      <router-link to="/completedWalks" class="btn mx-2 mt-4 mt-sm-0">
        Completed Trails
      </router-link>
    </div>
  </section>

  <section class="adventure-section pt-5 pb-5">
    <div class="container text-center">
      <img
        src="/assets/img/Walking.png"
        alt="Walking Wicklows Wilds"
        class="adventure-image"
      />
    </div>
  </section>

  <section class="full-width-image-section news green-sep">
    <div class="container text-center">
      <h1 class="news-header special-header text-center">Latest News</h1>
      <p class="news-subtext text-center">Whats New in Wicklow</p>
    </div>
  </section>

  <section class="news-section pt-lg-3 pb-15">
    <div class="container">
      <div class="row">
        <div v-for="(article, index) in articles" :key="index" class="row g-0">
          <!-- Dynamic Image Column -->
          <div
            :class="[
              'col-md-6',
              index % 2 === 0 ? 'order-1 order-md-1' : 'order-3 order-md-4',
            ]"
          >
            <img
              :src="article.image"
              :alt="article.title"
              class="w-100 mb-2"
              style="height: 100%; width: auto; object-fit: cover"
            />
          </div>
          <!-- Dynamic Text Column -->
          <div
            :class="[
              'col-md-6 bg-white p-5 d-flex flex-column justify-content-center',
              index % 2 === 0 ? 'order-2 order-md-2' : 'order-4 order-md-3',
            ]"
          >
            <h2 class="news-title">{{ article.title }}</h2>
            <p class="news-desc">{{ article.excerpt }}</p>
            <a
              :href="article.link"
              target="_blank"
              class="btn news-btn w-auto align-self-start"
            >
              Read More >
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--
  <section class="news-section pt-lg-3 pb-15">
    <div class="container">
      <div class="row">
        <div v-for="(update, index) in updates" :key="index" class="row g-0">
       
          <div
            :class="[
              'col-md-6',
              index % 2 === 0 ? 'order-1 order-md-1' : 'order-3 order-md-4',
            ]"
          >
            <img
              :src="update.Image"
              :alt="update.Title"
              class="w-100 mb-2"
              style="
                max-height: 550px;
                height: 100%;
                width: auto;
                object-fit: cover;
              "
            />
          </div>
        
          <div
            :class="[
              'col-md-6 bg-white p-5 d-flex flex-column justify-content-center',
              index % 2 === 0 ? 'order-2 order-md-2' : 'order-4 order-md-3',
            ]"
          >
            <h2 class="news-title">{{ update.Title }}</h2>
            <p class="news-desc">
              {{ update.News }}
            </p>
            <p class="news-date" style="font-size: 18px">
              {{ new Date(update.PostDate).toLocaleDateString() }}
            </p>
            <button
              class="btn news-btn w-auto align-self-start"
              @click="goToBlog(update.Id)"
            >
              Read More >
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
-->
  <section class="full-width-image-section contact white-sep"></section>

  <section class="signup-section pt-5 pb-5">
    <div class="container">
      <div class="row g-0">
        <!-- First Column: Image with Text -->
        <div
          class="col-md-6 d-flex flex-column justify-content-center signup-image-column text-white p-5 mt-5 mb-lg-5"
        >
          <h2 class="signup-header">Wicklow Trails</h2>
          <p class="signup-text">
            Sign up for our newsletter to stay updated on our walks, trails, and
            more!
          </p>
        </div>

        <!-- Second Column: Sign Up Form -->
        <div class="col-md-6 bg-white">
          <h2 class="signup-form-header text-white p-3 text-center">Sign Up</h2>
          <div class="px-5 pt-3 pb-5 pt-md-5">
            <form>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your name"
                />
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Your email"
                />
              </div>
              <div class="mb-3">
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Message"
                ></textarea>
              </div>
              <button type="submit" class="btn w-100">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="full-width-image-section footer green-sep"></section>

  <!-- <button @click="deleteDatabase" class="btn btn-default">
    Delete Database
  </button>
  -->
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import db from "@/db";
import { mapState, mapMutations } from "vuex";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  data() {
    return {
      tenantId: "", // Initial state
      posts: [],
      header: {},
      updates: [],
      showInstallButton: false,
      showDesktopPopup: false,
      articles: [],
    };
  },
  computed: {
    ...mapState(["deferredPrompt", "showInstallPopup"]),
    isBlogUrlValid() {
      return this.header.BlogURL && this.header.BlogURL.trim() !== "";
    },
  },
  created() {
    // Fetch the tenantId from local storage when the component is created
    this.tenantId = localStorage.getItem("tenantId");
  },
  async mounted() {
    this.showInstallButton = this.$showInstallButton;
    this.showDesktopPopup = this.$showDesktopPopup;

    try {
      // Fetch General Header data

      if (!this.tenantId) {
        throw new Error("Tenant ID not found in localStorage.");
      }

      var headerArray = await db.header
        .where("CustomerId")
        .equals(parseInt(this.tenantId))
        .toArray();

      if (headerArray.length > 0) {
        this.header = headerArray[0];
        console.log("Header:", this.header); // Log the header object

        // Log the specific value of BlogUrl
        console.log("header.BlogUrl:", this.header.BlogURL);

        // Log the value of isBlogUrlValid after header is set
        console.log("isBlogUrlValid:", this.isBlogUrlValid);
      } else {
        console.warn("No header data found for the given tenant ID.");
      }
    } catch (error) {
      console.error("Error fetching header data:", error);
    }

    if (this.header.BlogURL && this.header.BlogURL.trim() !== "") {
      //this.fetchRSS2(this.header.BlogURL);
      this.fetchRSS2();
    }

    this.fetchUpdates();
  },
  methods: {
    ...mapMutations(["setShowInstallPopup"]),
    promptForInstall() {
      // Check if the user is on iOS
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

      if (isIOS) {
        // Display a custom message for iOS users, guiding them to install the PWA manually
        alert(
          "To install this app on your iPhone or iPad, tap the share icon at the bottom of Safari and select 'Add to Home Screen'."
        );
        console.log(
          "No install prompt available for iOS, user must manually add to home screen."
        );
      } else if (this.deferredPrompt) {
        // For Android/Desktop users, continue with the deferred prompt
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the install prompt");
          } else {
            console.log("User dismissed the install prompt");
            // Store the dismissal to prevent prompting again in the same session
            localStorage.setItem("installDismissed", "true");
          }
          this.$store.commit("setDeferredPrompt", null); // Reset the deferredPrompt
          this.setShowInstallPopup(false); // Hide the install popup after user interaction
        });
      } else {
        console.log("No install prompt available");
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return isNaN(date.getTime()) ? "Unknown Date" : date.toLocaleDateString();
    },
    goToBlog(id) {
      this.$router.push({ name: "blog", params: { id } });
    },
    dismissInstallPopup() {
      this.setShowInstallPopup(false);
      // Store the dismissal to prevent prompting again in the same session
      localStorage.setItem("installDismissed", "true");
    },
    async fetchUpdates() {
      try {
        const customerId = localStorage.getItem("tenantId"); // Replace with the actual CustomerId
        const base64Credentials = btoa("Ian:Ennistymon1!");

        const url = "https://live-api-v2.waywyser.com/api/VisitorCard/GetNews";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            CustomerId: this.tenantId,
            Authorization: `Basic ${base64Credentials}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch updates.");
        }

        const updatesData = await response.json();
        this.updates = updatesData;
      } catch (error) {
        console.error("Error fetching updates:", error);
      }
    },
    async fetchRSS(blogUrl) {
      const apiUrl =
        "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fvisitwicklow.ie%2Fcategory%2Fnews%2Ffeed%2F&api_key=sxsemqxuwiupdstbyscdujlzdfb3szjo2top1pny";

      const response = await fetch(apiUrl);
      const data = await response.json();
      const items = data.items.slice(0, 3);

      for (let item of items) {
        const post = {
          title: item.title,
          link: item.link,
          image: await this.fetchImageFromPost(item.link),
        };
        this.posts.push(post);
      }
    },
    async fetchRSS2(feedUrl, newsPageUrl) {
      try {
        // /const response = await fetch("https://wicklowlsp.ie/news/");

        const proxyUrl = "https://cors-anywhere.herokuapp.com/";
        const targetUrl = "https://wicklowlsp.ie/news/";
        const response = await fetch(proxyUrl + targetUrl);

        const html = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        // Extract the first three articles
        const articles = [];
        const posts = doc.querySelectorAll(".post-item");
        posts.forEach((post, index) => {
          if (index < 3) {
            const title = post
              .querySelector(".post-title a")
              .textContent.trim();
            const link = post.querySelector(".post-title a").href;
            const image = post.querySelector(".image_wrapper img").src;
            const excerpt = post
              .querySelector(".post-excerpt")
              .textContent.trim();

            articles.push({ title, link, image, excerpt });
          }
        });

        this.articles = articles;
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    },
    async fetchImageFromPost(url) {
      //const response = await fetch(
      //  `https://cors-anywhere.herokuapp.com/${url}`
      //  );
      // const text = await response.text();
      //  const parser = new DOMParser();
      //  const doc = parser.parseFromString(text, "text/html");
      //const imgElement = doc.querySelector('div[itemprop="image"] img');
      //return imgElement ? imgElement.src : "img/logo-text.svg";
      return "img/logo-text.svg";
    },
  },
};
</script>

<style>
.install-popup {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  width: 95%; /* Nearly full screen width */
  max-width: 600px; /* Optional: limit the width on larger screens */
  text-align: center;
  z-index: 10000;
}

.install-button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #008cba;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-popup-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #aaa;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
</style>
