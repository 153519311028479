<template>
  <section
    v-if="poiItem"
    id="carouselExampleControls"
    class="carousel slide"
    data-bs-ride="carousel"
    data-bs-touch="true"
  >
    <div class="carousel-inner">
      <!-- <div class="carousel-item active">
        <ImageComponentAnimate
          v-if="card.CoverImage"
          :imageId="card.CoverImage"
        ></ImageComponentAnimate>
      </div>-->

      <div class="carousel-item active">
        <ImageComponent
          v-if="poiItem.imageId"
          :imageId="poiItem.imageId"
          class=""
          style="position: relative; top: -300px; width: 100%; height: auto"
        ></ImageComponent>
      </div>
    </div>
  </section>

  <section v-if="poiItem" class="full-width-image-section trail-info white-sep">
    <div class="container text-center"></div>
  </section>

  <section v-if="poiItem" class="trail-info-section">
    <div class="container text-center">
      <div class="trails-details">
        <h2 v-if="poiItem" class="heading">{{ poiItem.Title }}</h2>
        <h5 v-if="poiItem" class="sub-heading">{{ poiItem.SubTitle }}</h5>
        <p class="paragraph">
          {{ poiItem.Body }}
        </p>

        <h4 v-if="poiItem.Website" style="color: blue; margin-top: 50px">
          <a :href="poiItem.Website" target="_blank">Website</a>
        </h4>
        <h4 v-if="poiItem.DirectionsLink" style="color: blue">
          <a :href="poiItem.DirectionsLink" target="_blank">Directions</a>
        </h4>
      </div>
    </div>
  </section>

  <section class="full-width-image-section footer green-sep"></section>
</template>
<script>
import db from "@/db";
import LeafletMap from "@/components/LeafletMap.vue";
import ImageComponent from "@/components/ImageComponent.vue";

export default {
  components: {
    LeafletMap,
    ImageComponent,
  },
  data() {
    return {
      poiItem: { Image: "" },
    };
  },
  async mounted() {
    try {
      // Fetch card data based on the CardId parameter
      const Id = parseInt(this.$route.params.Id); // Convert to integer if necessary

      //only fetch the Gallery data rather than the whl;e card
      var poiData = await db.pois.where("MarkerId").equals(Id).toArray();

      this.poiItem = poiData[0];
    } catch (error) {
      console.error("Error fetching card data:", error);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
  },
};
</script>
