<template>
  <section v-if="blogEntry" class="blog-img">
    <img
      :src="blogEntry.Image"
      :alt="blogEntry.Title"
      class="blog-image"
      style="max-height: 300px; object-fit: cover; object-position: center"
    />
  </section>

  <section
    v-if="blogEntry"
    class="full-width-image-section white-pattern white-sep text-center"
  >
    <div class="container">
      <div class="blog-section">
        <h2 class="heading">{{ blogEntry.Title }}</h2>
        <h6>{{ new Date(blogEntry.PostDate).toLocaleDateString() }}</h6>

        <p>{{ blogEntry.News }}</p>
      </div>
    </div>
  </section>

  <section class="signup-section pt-5 pb-5">
    <div class="container">
      <div class="row g-0">
        <!-- First Column: Image with Text -->
        <div
          class="col-md-6 d-flex flex-column justify-content-center signup-image-column text-white p-5 mt-5 mb-lg-5"
        >
          <h2 class="signup-header">Wicklow Trails</h2>
          <p class="signup-text">
            Sign up for our newsletter to stay updated on our walks, trails, and
            more!
          </p>
        </div>

        <!-- Second Column: Sign Up Form -->
        <div class="col-md-6 bg-white">
          <h2 class="signup-form-header text-white p-3 text-center">Sign Up</h2>
          <div class="px-5 pt-3 pb-5 pt-md-5">
            <form>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your name"
                />
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Your email"
                />
              </div>
              <div class="mb-3">
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Message"
                ></textarea>
              </div>
              <button type="submit" class="btn w-100">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="full-width-image-section footer green-sep"></section>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      tenantId: "", // Initial state
      updates: [],
      blogEntry: null,
    };
  },
  created() {
    // Fetch the tenantId from local storage when the component is created
    this.tenantId = localStorage.getItem("tenantId");
  },
  async mounted() {
    await this.fetchUpdates(); // Fetch updates if not already fetched
    this.findBlogEntry(); // Filter the specific blog entry
  },
  methods: {
    async fetchUpdates() {
      try {
        const customerId = localStorage.getItem("tenantId"); // Replace with the actual CustomerId
        const base64Credentials = btoa("Ian:Ennistymon1!");

        const url = "https://live-api-v2.waywyser.com/api/VisitorCard/GetNews";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            CustomerId: this.tenantId,
            Authorization: `Basic ${base64Credentials}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch updates.");
        }

        const updatesData = await response.json();
        this.updates = updatesData;
      } catch (error) {
        console.error("Error fetching updates:", error);
      }
    },
    findBlogEntry() {
      // Find the blog entry by ID
      this.blogEntry = this.updates.find(
        update => update.Id === parseInt(this.id)
      );
      var test = this.id;
    },
  },
};
</script>
<style>
.full-width-image-section.white-pattern {
  background-image: none;
  background-color: #fff;
  padding-bottom: 70px;
}
</style>
