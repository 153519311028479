<template>
  <section class="full-width-image-section trails gray-bg">
    <div class="container">
      <div class="text-center">
        <h1 class="trails-header special-header">Points Of Interest</h1>
      </div>

      <div class="trails-search">
        <!-- Sort Button -->
        <button @click="toggleSortDirection" class="btn btn-outline-primary">
          Sort by Distance
          <span v-if="sortDirection === 'asc'">↑</span>
          <span v-else>↓</span>
        </button>
      </div>

      <div class="trails-lists" style="margin-top: 50px">
        <div
          class="trails-list"
          v-for="poi in filteredPOIs"
          :key="poi.MarkerId"
        >
          <router-link
            :to="'/point/' + poi.MarkerId"
            class="col-md-6 mb-4 pb-2"
            style="text-decoration: none; color: inherit"
          >
            <figure>
              <ImageComponent
                v-if="poi.imageId"
                :imageId="poi.imageId"
              ></ImageComponent>
            </figure>

            <div class="trails-details">
              <h2 class="heading">{{ poi.Title }}</h2>
              <h5 class="sub-heading">
                {{ poi.DistanceFromCurrentLocation.toFixed(2) }} km
              </h5>
              <a href="#" class="btn">Read More</a>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import db from "@/db"; // Adjust based on your project structure
import ImageComponent from "@/components/ImageComponent.vue";

export default {
  name: "POIsView",
  components: {
    ImageComponent,
  },
  props: {
    CardId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: "",
      pois: [],
      currentLat: null,
      currentLng: null,
      sortDirection: "asc",
    };
  },
  computed: {
    filteredPOIs() {
      let result = this.pois.map(poi => {
        const distance = this.getDistanceFromLatLonInKm(
          this.currentLat,
          this.currentLng,
          parseFloat(poi.Latitude),
          parseFloat(poi.Longitude)
        );
        return { ...poi, DistanceFromCurrentLocation: distance };
      });

      if (this.searchQuery) {
        result = result.filter(
          poi =>
            poi.Title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            poi.Body.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      // Sort by distance, respecting the current sort direction
      result.sort((a, b) => {
        const sortMultiplier = this.sortDirection === "asc" ? 1 : -1;
        return (
          (a.DistanceFromCurrentLocation - b.DistanceFromCurrentLocation) *
          sortMultiplier
        );
      });

      return result;
    },
  },
  methods: {
    toggleSortDirection() {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      console.log("Sort direction toggled:", this.sortDirection);
    },
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in kilometers
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },

    async fetchPOIs() {
      try {
        const cardIdNumber = Number(this.CardId);
        if (isNaN(cardIdNumber)) {
          console.error("Invalid CardId:", this.CardId);
          return;
        }
        const card = await db.cards
          .where("CardId")
          .equals(cardIdNumber)
          .first();
        debugger;
        if (card && card.POI) {
          var test = card.POI;
          this.pois = card.POI.filter(poi => poi.TypeId === "1");
        } else {
          console.error("No POIs found for this CardId");
        }
      } catch (error) {
        console.error("Error fetching POIs:", error);
      }
    },
    getCurrentLocationAndFetchPOIs() {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.currentLat = position.coords.latitude;
          this.currentLng = position.coords.longitude;
          //this.fetchPOIs(); // Fetch POIs after getting current location
        },
        error => {
          console.error("Geolocation error:", error);
          //this.fetchPOIs(); // Still fetch POIs even if geolocation fails
        }
      );
      this.fetchPOIs();
    },
  },
  mounted() {
    this.getCurrentLocationAndFetchPOIs();

    // Initialize searchQuery from the route's query parameters
    if (this.$route.query.search) {
      this.searchQuery = this.$route.query.search;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getCurrentLocationAndFetchPOIs();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.getCurrentLocationAndFetchPOIs();
    next();
  },
  watch: {
    "$route.params.CardId": {
      immediate: true,
      handler() {
        this.getCurrentLocationAndFetchPOIs();
      },
    },
    "$route.query.search"(newQuery) {
      this.searchQuery = newQuery || ""; // Update searchQuery dynamically
    },
  },
  activated() {
    this.getCurrentLocationAndFetchPOIs();
  },
};
</script>

<style scoped></style>
