<template>
  <section class="full-width-image-section report-issue gray-bg">
    <div class="container">
      <div v-if="statusMessage" class="status-message">
        {{ statusMessage }}
      </div>

      <!-- Spinner -->
      <div v-if="isLoading" class="spinner"></div>

      <div class="text-center">
        <h1 class="trails-header special-header">Report an Issue</h1>
      </div>
      <form @submit.prevent>
        <!--
        <div class="mb-3">
          <input type="text" class="form-control" placeholder="Your Name" />
        </div>
        <div class="mb-3">
          <input
            type="number"
            class="form-control"
            placeholder="Contact Number"
          />
        </div>
        <div class="mb-3">
          <input type="email" class="form-control" placeholder="Email" />
        </div>
        <div class="mb-3">
          <input
            type="walkissue"
            class="form-control"
            placeholder="Name of walk with issue"
          />
        </div>
        -->
        <div class="mb-3">
          <textarea
            id="issueDescription"
            class="form-control"
            v-model="issueDescription"
            rows="5"
            placeholder="Describe the issue observed..."
          ></textarea>
        </div>

        <div class="text-center upload-picture mb-1">
          Upload picture from phone:
        </div>
        <div class="mb-3">
          <div class="choose-file">
            <input
              type="file"
              id="photoUpload"
              class="file-name form-control"
              placeholder="Choose file:"
              @change="handleFileUpload"
            />
          </div>
        </div>

        <div class="form-group mt-3">
          <button class="btn btn-primary" @click="captureLocation">
            Capture current location
          </button>
          If you are still at the location of the issue, push this button to
          include your location.
          <div v-if="locationCaptured" class="mt-2">
            <p>
              Location captured: {{ currentLocation.latitude }},
              {{ currentLocation.longitude }}
            </p>
          </div>
        </div>

        <button id="btnSave" type="submit" class="btn w-100" @click="saveIssue">
          Submit
        </button>
      </form>
      <p class="text-center alternatively">
        Alternatively please email a detailed desription along with any relevant
        pictures to:<br />
        <a href="mailto:info@visitwicklow.ie">info@visitwicklow.ie</a>
      </p>
    </div>
  </section>

  <section class="signup-section pt-5 pb-5">
    <div class="container">
      <div class="row g-0">
        <!-- First Column: Image with Text -->
        <div
          class="col-md-6 d-flex flex-column justify-content-center signup-image-column text-white p-5 mt-5 mb-lg-5"
        >
          <h2 class="signup-header">Wicklow Trails</h2>
          <p class="signup-text">
            Sign up for our newsletter to stay updated on our walks, trails, and
            more!
          </p>
        </div>

        <!-- Second Column: Sign Up Form -->
        <div class="col-md-6 bg-white">
          <h2 class="signup-form-header text-white p-3 text-center">Sign Up</h2>
          <div class="px-5 pt-3 pb-5 pt-md-5">
            <form>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your name"
                />
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Your email"
                />
              </div>
              <div class="mb-3">
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Message"
                ></textarea>
              </div>

              <button type="submit" class="btn w-100">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="full-width-image-section footer green-sep"></section>
</template>

<script>
import db from "@/db"; // Adjust the path to your IndexedDB setup

export default {
  data() {
    return {
      issueDescription: "",
      photo: null,
      currentLocation: null,
      locationCaptured: false,
      statusMessage: "",
      isLoading: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.photo = file;
      }
    },
    captureLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.currentLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            this.locationCaptured = true;
          },
          error => {
            console.error("Geolocation error:", error);
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    },
    async saveIssue() {
      this.isLoading = true; // Start loading
      this.statusMessage = "Saving your issue...";
      const issueData = {
        cardId: parseInt(this.$route.params.CardId),
        description: this.issueDescription,
        photo: this.photo,
        latitude: this.currentLocation?.latitude,
        longitude: this.currentLocation?.longitude,
        timestamp: new Date().toISOString(),
      };

      try {
        // Try sending the issue data to the REST API
        await this.sendIssueToAPI(issueData);
        this.statusMessage = "Issue saved successfully!";
        setTimeout(() => (this.statusMessage = ""), 5000); // Clear after 5 seconds
      } catch (error) {
        console.error("Error sending issue to API:", error);
        // Save to IndexedDB if the API call fails
        try {
          await db.issues.add(issueData);
          this.registerBackgroundSync();
          this.statusMessage = "Issue saved locally and will be synced later.";
          setTimeout(() => (this.statusMessage = ""), 5000); // Clear after 5 seconds
        } catch (dbError) {
          console.error("Error saving issue to IndexedDB:", dbError);
          this.statusMessage = "Failed to save the issue.";
        }
      } finally {
        this.isLoading = false; // Stop loading
      }
    },
    async sendIssueToAPI(issueData) {
      const formData = new FormData();
      formData.append("description", issueData.description);
      if (issueData.photo) {
        formData.append("photo", issueData.photo);
      }
      if (issueData.latitude && issueData.longitude) {
        formData.append("latitude", issueData.latitude);
        formData.append("longitude", issueData.longitude);
      }
      formData.append("VisitorCardId", issueData.cardId);
      formData.append("timestamp", issueData.timestamp);

      const base64Credentials = btoa("Ian:Ennistymon1!");
      const response = await fetch(
        //"http://localhost:10775/api/VisitorCard/ReportIssue",
        "https://live-api-v2.waywyser.com/api/VisitorCard/ReportIssue",
        {
          method: "POST",
          headers: {
            CustomerId: localStorage.getItem("tenantId"), // Replace with actual CustomerId
            Authorization: `Basic ${base64Credentials}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send issue to API");
      }
    },
    async registerBackgroundSync() {
      if ("serviceWorker" in navigator && "SyncManager" in window) {
        try {
          const registration = await navigator.serviceWorker.ready;
          await registration.sync.register("sync-issues");
        } catch (error) {
          console.error("Error registering background sync:", error);
        }
      } else {
        console.warn("Background sync is not supported in this browser.");
      }
    },
  },
};
</script>

<style scoped>
.container {
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.custom-file-input {
  display: none;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.btn-primary,
.btn-success {
  margin-top: 20px;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

#btnSave {
  width: 300px;
  height: 60px;
  font-size: 24px;
}

.status-message {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f8ff;
  border: 1px solid #b0c4de;
  color: #333;
  font-size: 16px;
  border-radius: 4px;
}

.spinner {
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.spinner:before {
  content: "";
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
