<template>
  <section class="full-width-image-section trails gray-bg">
    <div class="container">
      <div class="text-center">
        <h1 class="trails-header special-header">Trails</h1>
      </div>

      <div class="trails-search" hidden>
        <input
          type="text"
          class="form-control mb-4"
          placeholder="Search Walks..."
          v-model="searchQuery"
        />
      </div>

      <div class="trails-search">
        <div
          class="buttonsx"
          style="position: Relative; top: -10px; font-size: 20px"
        >
          <buttonx :class="{ active: registered }" @click="registerForBadges">
            <span v-html="firstButtonText"></span>
          </buttonx>

          <buttonx
            :class="{ active: !registered }"
            @click="unRegisterForBadges"
          >
            {{ secondButtonText }}
          </buttonx>
        </div>
      </div>

      <div class="trails-filters">
        <div class="trails-filter">
          <label>Grade</label>
          <div class="select-icon">
            <select
              id="gradeFilter"
              class="form-control input-field"
              v-model="gradeFilter"
            >
              <option value="">All</option>
              <option value="Strenuous">Strenuous</option>
              <option value="Moderate">Moderate</option>
              <option value="Easy">Easy</option>
            </select>
          </div>
        </div>

        <div class="trails-filter">
          <label>Type</label>
          <div class="select-icon">
            <select
              id="typeFilter"
              class="form-control input-field"
              v-model="typeFilter"
            >
              <option value="">All</option>
              <option value="Linear">Linear</option>
              <option value="Loop">Loop</option>
            </select>
          </div>
        </div>

        <div class="trails-filter">
          <label>Completed</label>
          <div class="select-icon">
            <select
              id="completedFilter"
              class="form-control input-field"
              v-model="completedFilter"
            >
              <option value="">All</option>
              <option value="completed">Completed</option>
              <option value="notCompleted">Not Completed</option>
            </select>
          </div>
        </div>

        <div class="trails-filter">
          <label>Length (km)</label>
          <div class="range-slider">
            <div
              id="slider_thumb"
              class="range-slider_thumb"
              @mousedown="startDragging"
            ></div>
            <div class="range-slider_line">
              <div id="slider_line" class="range-slider_line-fill"></div>
            </div>
            <input
              ref="slider_input"
              id="slider_input"
              class="range-slider_input"
              type="range"
              v-model="lengthFilter"
              :min="0"
              :max="maxLength"
              step="0.1"
              @input="showSliderValue"
            />
          </div>
        </div>
      </div>

      <div class="trails-lists">
        <div class="trails-list" v-for="card in filteredCards" :key="card.id">
          <router-link
            :to="'/information/' + card.CardId"
            class="col-md-6 mb-4 pb-2"
            style="text-decoration: none; color: inherit"
            @click.native="saveScrollPosition"
          >
            <!-- Conditionally show the badge if the card is completed -->
            <div v-if="card.isCompleted" class="completed-badge">
              <small class="badge badge-success">Completed</small>
            </div>
            <figure>
              <ImageComponent
                v-if="card.CoverImage"
                :imageId="card.CoverImage"
              ></ImageComponent>
            </figure>
            <ul class="list-table-row">
              <li class="strenuous">
                <img src="/assets/img/trails.svg" />{{ card.Grading }}
              </li>
              <li>
                <img src="/assets/img/timer.svg" />{{
                  formatTime(card.Duration)
                }}
              </li>
              <li>
                <img src="/assets/img/distance.svg" />{{ card.Distance }} km
              </li>
              <li><img src="/assets/img/loop.svg" />{{ card.ShapeName }}</li>
            </ul>
            <div class="trails-details">
              <h2 class="heading">{{ card.Name }}</h2>
              <h5 class="sub-heading">{{ card.SubName }}</h5>
              <a href="#" class="btn">Read More</a>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section class="signup-section pt-5 pb-5">
    <div class="container">
      <div class="row g-0">
        <!-- First Column: Image with Text -->
        <div
          class="col-md-6 d-flex flex-column justify-content-center signup-image-column text-white p-5 mt-5 mb-lg-5"
        >
          <h2 class="signup-header">Wicklow Trails</h2>
          <p class="signup-text">
            Sign up for our newsletter to stay updated on our walks, trails, and
            more!
          </p>
        </div>

        <!-- Second Column: Sign Up Form -->
        <div class="col-md-6 bg-white">
          <h2 class="signup-form-header text-white p-3 text-center">Sign Up</h2>
          <div class="px-5 pt-3 pb-5 pt-md-5">
            <form>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your name"
                />
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Your email"
                />
              </div>
              <div class="mb-3">
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Message"
                ></textarea>
              </div>
              <button type="submit" class="btn w-100">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="full-width-image-section footer green-sep"></section>
</template>
<script>
import Vue from "vue";
import db from "@/db"; // Path to your Dexie database setup
import ImageComponent from "@/components/ImageComponent.vue";

export default {
  name: "YourComponentName",
  components: {
    ImageComponent,
  },
  data() {
    return {
      searchQuery: "",
      cards: [],
      gradeFilter: "",
      typeFilter: "",
      lengthFilter: 0,
      maxLength: 10,
      currentLat: null,
      currentLng: null,
      sliderValuePosition: "0%",
      registered: false,
      userEmail: null,
      completedFilter: "",
    };
  },
  created() {
    this.checkRegistrationStatus();
    this.userEmail = localStorage.getItem("userEmail") || "";
  },
  computed: {
    firstButtonText() {
      return this.registered
        ? "Registered for Badges  <i class='fa fa-check'></i>"
        : "Register for Badges";
    },
    secondButtonText() {
      return this.registered
        ? "Unregister for Badges"
        : "UnRegistered for Badges";
    },
    filteredCards() {
      const cardsWithDistance = this.cards.map(card => {
        const distance = this.getDistanceFromLatLonInKm(
          this.currentLat,
          this.currentLng,
          card.StartLocationLat,
          card.StartLocationLng
        );
        return { ...card, DistanceFromCurrentLocation: distance };
      });

      console.log(
        "Filters - searchQuery:",
        this.searchQuery,
        "gradeFilter:",
        this.gradeFilter,
        "typeFilter:",
        this.typeFilter,
        "lengthFilter:",
        this.lengthFilter
      );

      let result = cardsWithDistance.filter(card => {
        const matchesSearchQuery = card.Name.toLowerCase().includes(
          this.searchQuery.toLowerCase()
        );
        const matchesGrade =
          !this.gradeFilter || card.Grading === this.gradeFilter;
        const matchesType =
          !this.typeFilter || card.ShapeName === this.typeFilter;
        const matchesLength =
          parseFloat(card.Distance) <= parseFloat(this.lengthFilter);

        // Completed filter logic
        const matchesCompleted =
          !this.completedFilter ||
          (this.completedFilter === "completed" && card.isCompleted) ||
          (this.completedFilter === "notCompleted" && !card.isCompleted);

        return (
          matchesSearchQuery &&
          matchesGrade &&
          matchesType &&
          matchesLength &&
          matchesCompleted
        );
      });

      result.sort(
        (a, b) => a.DistanceFromCurrentLocation - b.DistanceFromCurrentLocation
      );

      return result;
    },
  },
  watch: {
    maxLength(newMaxLength) {
      if (newMaxLength > 0) {
        this.$nextTick(() => {
          this.lengthFilter = newMaxLength;
          this.showSliderValue();
        });
      }
    },
    lengthFilter() {
      this.showSliderValue();
    },
    // Watch for route changes to handle navigation with query parameters
    $route(to) {
      if (to.query.search) {
        this.searchQuery = to.query.search; // Update the search query
      }
    },
  },
  methods: {
    async checkCompletedTrails() {
      // Loop over the original cards and check if the card exists in completedTrails
      await db.transaction("r", db.completedTrails, async () => {
        for (const card of this.cards) {
          const completed = await db.completedTrails.get({
            VisitorCardId: card.CardId,
          });

          // Set the isCompleted flag directly, no need for $set in Vue 3
          card.isCompleted = !!completed; // true if completed exists, false otherwise

          console.log(card); // Log each card with the completed status
        }
      });
    },
    async fetchCompletedWalks() {
      try {
        const customerId = localStorage.getItem("tenantId"); // Replace with the actual CustomerId
        const base64Credentials = btoa("Ian:Ennistymon1!");

        const response = await fetch(
          `https://live-api-v2.waywyser.com/api/VisitorCard/GetCompletedWalks?userId=${this.userEmail}&customerId=${customerId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              CustomerId: customerId,
              Authorization: `Basic ${base64Credentials}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch completed walks.");
        }

        const responseText = await response.text();
        if (!responseText) {
          console.warn("Empty response");
          return;
        }

        const walks = JSON.parse(responseText).Walks;

        // Delete all existing records and insert the new ones
        await db.transaction("rw", db.completedTrails, async () => {
          await db.completedTrails.clear(); // Clear all entries in completedTrails
          for (const walk of walks) {
            try {
              // Check if a similar walk already exists
              const existingWalk = await db.completedTrails
                .where({
                  VisitorCardId: walk.CardId,
                  emailaddress: walk.UserId,
                })
                .first();

              if (!existingWalk) {
                // Add the walk only if it doesn't already exist
                await db.completedTrails.add({
                  VisitorCardId: walk.CardId,
                  emailaddress: walk.UserId,
                  datetime: walk.DateTime,
                });
              } else {
                console.log("Duplicate walk skipped:", walk);
              }
            } catch (error) {
              console.error("Error processing walk:", walk, error);
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    checkRegistrationStatus() {
      this.registered = !!localStorage.getItem("userEmail");
    },
    registerForBadges() {
      this.$router.push({
        name: "register",
        params: { CardId: 0 },
      });
    },
    unRegisterForBadges() {
      localStorage.removeItem("userEmail");
      this.registered = !!localStorage.getItem("userEmail");
    },
    saveScrollPosition() {
      const scrollPosition = window.scrollY;
      sessionStorage.setItem("scrollPosition2", scrollPosition);
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in kilometers
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in km
      return distance;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    async fetchCards() {
      this.cards = await db.cards.where("IsActive").equals("True").toArray();
      this.maxLength =
        Math.max(...this.cards.map(card => parseFloat(card.Distance))) + 1;
      console.log("Max Length: " + this.maxLength); // Ensure maxLength is set correctly

      await this.checkCompletedTrails();
    },
    updateSliderValuePosition() {
      //const input = this.$refs.slider; // Slider input
      //const thumb = document.getElementById("slider_thumb"); // Thumb element
      //const line = document.getElementById("slider_line"); // Line element
      //if (!input || !thumb || !line) return;
      //const value = this.lengthFilter / this.maxLength; // Value as a percentage (0 to 1)
      //// const sliderWidth = input.offsetWidth; // Total slider width
      //const thumbWidth = thumb.offsetWidth; // Width of the thumb
      // const thumbPosition = value * (sliderWidth - thumbWidth); // Adjust for thumb width
      //thumb.style.left = `${thumbPosition}px`;
      // line.style.width = `${value * 100}%`;
      // thumb.innerHTML = `${this.lengthFilter} km`;
    },
    showSliderValue() {
      const slider_input = document.getElementById("slider_input");
      const slider_thumb = document.getElementById("slider_thumb");
      const slider_line = document.getElementById("slider_line");

      if (!slider_input || !slider_thumb || !slider_line) {
        console.error("Slider elements not found");
        return;
      }

      // Get the slider value and calculate position
      const value = parseFloat(slider_input.value);
      const max = parseFloat(slider_input.max);
      const bulletPosition = value / max; // Position as a percentage (0 to 1)

      // Calculate available space for the thumb to move
      const sliderWidth = slider_input.offsetWidth;
      const thumbWidth = slider_thumb.offsetWidth;
      const space = sliderWidth - thumbWidth;

      // Constrain the thumb position within bounds
      const left = Math.max(0, Math.min(bulletPosition * space, space));

      // Update thumb position
      slider_thumb.style.left = `${left}px`;

      // Update progress line width
      slider_line.style.width = `${bulletPosition * 100}%`;

      // Update thumb text
      slider_thumb.innerHTML = `${value.toFixed(1)} km`;
    },
    handleDragging(event) {
      if (!this.dragging) return;

      const slider_input = this.$refs.slider_input;
      const slider_thumb = document.getElementById("slider_thumb");
      const rect = slider_input.getBoundingClientRect();
      const thumbWidth = slider_thumb.offsetWidth;

      // Calculate the mouse position relative to the slider
      const mouseX = event.clientX - rect.left;

      // Clamp the mouse position within the slider bounds
      const constrainedMouseX = Math.max(0, Math.min(mouseX, rect.width));

      // Convert the constrained position to a slider value
      const percentage = constrainedMouseX / rect.width;
      const newValue = percentage * parseFloat(slider_input.max);

      // Update slider value
      slider_input.value = newValue.toFixed(1);

      // Update slider visuals
      this.showSliderValue();
    },
    stopDragging() {
      this.dragging = false;

      // Remove event listeners
      window.removeEventListener("mousemove", this.handleDragging);
      window.removeEventListener("mouseup", this.stopDragging);
    },
    formatTime(minutes) {
      const days = Math.floor(minutes / 1440);
      const hours = Math.floor((minutes % 1440) / 60);
      const mins = minutes % 60;
      let formattedTime = "";

      if (days > 0) {
        formattedTime += `${days} day${days > 1 ? "s" : ""}`;
      }

      if (hours > 0) {
        if (formattedTime) formattedTime += " ";
        formattedTime += `${hours} hr${hours > 1 ? "s" : ""}`;
      }

      if (mins > 0) {
        if (formattedTime) formattedTime += " ";
        formattedTime += `${mins} min${mins > 1 ? "s" : ""}`;
      }

      return formattedTime || "0 mins";
    },
    initializePopover() {
      const popoverTarget = document.querySelector("#popover-target");

      if (popoverTarget) {
        let popoverInstance = bootstrap.Popover.getInstance(popoverTarget);
        if (!popoverInstance) {
          console.log("Popover not found, initializing...");
          popoverInstance = new bootstrap.Popover(popoverTarget, {
            trigger: "focus", // Keeps popover open until clicking elsewhere
            html: true, // Enables HTML content
            placement: "top", // Places the popover above the element
          });
        }
        console.log("Popover initialized successfully.");
      } else {
        console.error("#popover-target not found in DOM.");
      }
    },
  },
  mounted() {
    $(function () {
      $('[data-toggle="popover"]').popover();
    });

    this.fetchCompletedWalks();

    // Restore the scroll position if it exists
    const savedPosition = sessionStorage.getItem("scrollPosition2");
    //console.log("scrlPos " + savedPosition);
    if (savedPosition) {
      // Use nextTick to ensure the DOM is fully loaded before scrolling
      this.$nextTick(() => {
        setTimeout(() => {
          window.scrollTo(0, parseInt(savedPosition, 10));
        }, 100);
        sessionStorage.removeItem("scrollPosition2"); // Clean up the saved position
      });
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }

    navigator.geolocation.getCurrentPosition(
      position => {
        this.currentLat = position.coords.latitude;
        this.currentLng = position.coords.longitude;
      },
      error => {
        console.error("Geolocation error:", error);
        // Handle location error (e.g., user denied location access)
      }
    );

    // Initialize searchQuery from the route's query parameters
    if (this.$route.query.search) {
      this.searchQuery = this.$route.query.search;
    }

    const slider_input = document.getElementById("slider_input");

    if (!slider_input) {
      console.error("Slider input not found");
      return;
    }
    // Fetch cards and set up the slider
    this.fetchCards().then(() => {
      slider_input.max = this.maxLength; // Dynamically set the slider's max value
      this.lengthFilter = this.maxLength; // Initialize slider to the max value
      this.showSliderValue(); // Update visuals
    });

    // Initialize slider value and position
    this.showSliderValue();

    // Add event listeners
    window.addEventListener("resize", this.showSliderValue);
    slider_input.addEventListener("input", this.showSliderValue);
  },

  beforeDestroy() {
    // Clean up event listeners
    window.removeEventListener("resize", this.showSliderValue);

    const slider_input = document.getElementById("slider_input");
    if (slider_input) {
      slider_input.removeEventListener("input", this.showSliderValue);
    }
  },
};
</script>

<style scoped>
.blog-item img {
  width: 540px;
  height: 360px;
  object-fit: cover; /* This will cover the area, cropping the image as needed. Use 'contain' to fit the whole image without cropping */
}

.sunny-bright {
  filter: brightness(110%) contrast(110%) saturate(105%);
}

.blog-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}
.blog-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.position-relative {
  position: relative;
}
.card-distance {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
}

.card-info {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 15%;
}

.card-info div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  padding: 5px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-info div:hover {
  transform: translateY(-3px); /* Lift the box slightly */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Increase shadow for hover effect */
}

.card-info .grade {
  background-color: #3b82f6;
}

.card-info .length {
  background-color: #34d399;
}

.card-info .time {
  background-color: #fbbf24;
}

.card-info .type {
  background-color: #f87171;
}

.bg-white {
  background-color: white;
}
.p-4 {
  padding: 16px;
}
.card-name {
  font-size: 1.25rem;
  font-weight: bold;
}
.card-sub-name {
  font-size: 1rem;
  color: gray;
}
.card-grading {
  font-size: 0.875rem;
  color: green;
}
.card-distance-from-location {
  font-size: 0.875rem;
  color: green;
}
.d-flex {
  display: flex;
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}

.d-flex.justify-content-between {
  background-color: whitesmoke;
  padding: 10px;
  margin-top: 10px;
}

.filters {
  display: flex;
  gap: 1rem;
}

.filters .form-group {
  flex: 1;
}

h6,
.h6 {
  font-size: 1rem;
  font-weight: 1200 !important;
  color: #666666;
}

.buttonsx {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  gap: 1px;
}

buttonx {
  padding: 30px 40px;
  border: 1px solid #000;
  background: #fff;
  cursor: pointer;
  width: 380px;
}

buttonx.active {
  background: #8dc63f;
  color: #fff;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  opacity: 1;
}

.help-icon {
  font-size: 24px;
  cursor: pointer;
  top: 10px;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #838181;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -250px;
  opacity: 1 !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.completed-badge {
  /*position: relative;*/
  top: -100px;
  padding: 30px;
  background-color: #0be20b;
}
.completed-badge .badge {
  font-size: 19px;
  background-color: #0be20b;
}
</style>
