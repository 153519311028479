<!-- src/layouts/MainLayout.vue -->
<template>
  <!-- Footer Start -->

  <footer class="footer-section pb-5 pt-15">
    <div class="container">
      <div class="row text-white">
        <!-- First Column: Logo and Supported by -->
        <div class="col-lg-4 text-center text-lg-start">
          <img
            src="/assets/img/logo-green.svg"
            alt="Logo"
            class="mb-3 footer-logo"
          />
          <p class="support-text pt-5">Proudly Supported by:</p>
          <div
            class="d-flex flex-wrap justify-content-center justify-content-lg-start footer-partners"
          >
            <div class="top-logo">
              <img src="/assets/img/logo-wicklow.svg" alt="Wicklow Outdoors" />
              <img src="/assets/img/logo-sport.svg" alt="Sport Ireland" />
            </div>
            <div class="bottom-logo">
              <img
                src="/assets/img/wicklow-endless.svg"
                alt="Wicklow Endless"
              />
              <img src="/assets/img/cwp-logo.svg" alt="CWP" />
              <img src="/assets/img/drcd-Logo.png" alt="DRCD" />
            </div>
          </div>
        </div>

        <!-- Second Column: Useful Links -->
        <div class="col-lg-4 text-align-right">
          <h3 class="footer-title">USEFUL LINKS:</h3>
          <ul class="list-unstyled">
            <li>
              <a
                href="#"
                class="footer-link"
                @click.prevent="showEmergencyModal = true"
              >
                Emergency Services CALL 112
              </a>
            </li>
            <!--<li><a href="#" class="footer-link">Report an issue</a></li>-->
            <li>
              <a href="https://www.met.ie/" class="footer-link">Weather</a>
            </li>
            <li>
              <a href="https://www.transportforireland.ie/" class="footer-link"
                >Transport</a
              >
            </li>
          </ul>
        </div>
        <!-- Emergency Modal -->
        <EmergencyModal
          v-if="showEmergencyModal"
          :isVisible="showEmergencyModal"
          @close="showEmergencyModal = false"
        />

        <!-- Third Column: Contact Us -->
        <div class="col-lg-4 text-align-right">
          <h3 class="footer-title">CONTACT US:</h3>
          <ul class="contact-us list-unstyled">
            <li>Wicklow County Tourism</li>
          </ul>
          <!--<div>
                        <img src="//assets/img/phone.svg" alt="Phone">
                        <a href="tel:+35340420100" class="contact-link mx-2">+353 404 20100</a>
                    </div>-->
          <div class="mt-2">
            <img src="/assets/img/envelope.svg" alt="Email" />
            <a href="mailto:info@visitwicklow.ie" class="contact-link mx-2"
              >info@visitwicklow.ie</a
            >
          </div>

          <div class="social-icons mt-4">
            <!-- Facebook -->

            <a
              v-if="socialLinks.Facebook"
              :href="socialLinks.Facebook"
              target="_blank"
            >
              <img
                src="/assets/img/social-green-facebook.svg"
                alt="Facebook"
                class="me-2"
              />
            </a>

            <!-- Instagram -->
            <a
              v-if="socialLinks.Instagram"
              :href="socialLinks.Instagram"
              target="_blank"
            >
              <img
                src="/assets/img/social-green-instagram.svg"
                alt="Instagram"
                class="social-icon"
              />
            </a>

            <!-- Youtube -->
            <a
              v-if="socialLinks.Youtube"
              :href="socialLinks.Youtube"
              target="_blank"
            >
              <img
                src="/assets/img/social-green-youtube.svg"
                alt="YouTube"
                class="social-icon"
              />
            </a>

            <!--
            <a href="#" target="_blank"
              ><img
                src="/assets/img/social-green-tiktok.svg"
                alt="TikTok"
                class="social-icon"
            /></a>
            -->

            <!-- Twitter -->
            <a
              v-if="socialLinks.Twitter"
              :href="socialLinks.Twitter"
              target="_blank"
            >
              <img
                src="/assets/img/social-green-x.svg"
                alt="X"
                class="social-icon"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="developed-by">
        Developed by Evolve Technologies <span>|</span> Design by
        <a href="https://lighthousedesign.ie/" target="_blank"
          >LighthouseDesign.ie</a
        >
        <span>|</span> All rights reserved.
      </div>
    </div>
  </footer>
  <button
    type="button"
    class="btn btn-danger btn-floating btn-lg"
    id="btn-back-to-top"
    @click="backToTop"
  >
    ↑
  </button>
</template>

<script>
import db from "@/db";
import EmergencyModal from "./EmergencyModal.vue";

export default {
  name: "Header",
  components: {
    EmergencyModal,
  },
  created() {
    // Fetch the tenantId from local storage when the component is created
    this.tenantId = localStorage.getItem("tenantId");
    this.loadSocialLinks();
  },
  data() {
    return {
      showEmergencyModal: false,
      tenantId: "", // Initial state
      header: {},
      version: null,
      socialLinks: {},
    };
  },
  computed: {
    titleMain() {
      if (this.header && this.header.Title) {
        // Split the title into words
        const words = this.header.Title.split(" ");
        // If there is more than one word, return all except the last one
        return words.length > 1
          ? words.slice(0, -1).join(" ")
          : this.header.Title;
      }
      return ""; // Return an empty string if header.Title is not defined
    },
    titleLast() {
      if (this.header && this.header.Title) {
        // Split the title into words
        const words = this.header.Title.split(" ");
        // Return the last word if there is more than one
        return words.length > 1 ? words[words.length - 1] : "";
      }
      return ""; // Return an empty string if header.Title is not defined
    },
    isHomePage() {
      return this.$route.name === "home"; // Ensure 'home' is the name of your homepage route
    },
    formattedAddress() {
      return this.header.Address
        ? this.header.Address.replace(/\n/g, "<br/>")
        : "";
    },
  },
  async mounted() {
    this.version = localStorage.getItem("dbVersion");
    try {
      // Fetch General Header data

      if (!this.tenantId) {
        throw new Error("Tenant ID not found in localStorage.");
      }

      var headerArray = await db.header
        .where("CustomerId")
        .equals(parseInt(this.tenantId))
        .toArray();

      if (headerArray.length > 0) {
        this.header = headerArray[0];
        console.log("Header:", this.header); // Log the header object

        // Log the specific value of BlogUrl
        console.log("header.BlogUrl:", this.header.BlogURL);

        // Log the value of isBlogUrlValid after header is set
        console.log("isBlogUrlValid:", this.isBlogUrlValid);
      } else {
        console.warn("No header data found for the given tenant ID.");
      }
    } catch (error) {
      console.error("Error fetching header data:", error);
    }

    // Add scroll listener on mount
    window.addEventListener("scroll", this.scrollFunction);
  },
  beforeDestroy() {
    // Remove scroll listener to prevent memory leaks
    window.removeEventListener("scroll", this.scrollFunction);
  },
  methods: {
    async loadSocialLinks() {
      // Fetch the tenantId from local storage
      this.tenantId = localStorage.getItem("tenantId");

      try {
        // Fetch the first header record from IndexedDB
        const headerData = await db.header.toCollection().first();

        if (headerData) {
          // Populate socialLinks object
          this.socialLinks = {
            Facebook: headerData.Facebook,
            Instagram: headerData.Instagram,
            Youtube: headerData.Youtube,
            Twitter: headerData.Twitter,
          };
        }

        // Wait for DOM updates to complete
        // /await nextTick();

        console.log("Social links rendered in DOM:", this.socialLinks);
      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    },
    scrollFunction() {
      const mybutton = document.getElementById("btn-back-to-top");
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    },
    backToTop() {
      // Scroll to the top
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
    },
    deleteDatabase() {
      const dbName = "AppDatabase";

      const request = indexedDB.deleteDatabase(dbName);

      request.onsuccess = () => {
        console.log("Database deleted successfully");
        this.redirectHome();
      };

      request.onerror = event => {
        console.error("Error deleting database:", event);
      };

      request.onblocked = () => {
        console.warn("Database deletion blocked");
      };
    },
    redirectHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
<style scoped>
.footer {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
}
</style>
