<template>
  <LeafletMap :id="Id" />
</template>

<script>
// @ is an alias to /src
import LeafletMap from "@/components/LeafletMapMain.vue";

export default {
  name: "HomeView",
  components: {
    LeafletMap,
  },
  data() {
    return {
      Id: 0,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
  },
  async mounted() {
    try {
      const routeId = this.$route.params.Id;
      this.Id = Number.isInteger(parseInt(routeId)) ? parseInt(routeId) : 0; // Default to 0 if not a number
    } catch (error) {
      console.error("Error fetching card data:", error);
    }
  },
};
</script>
<style scoped>
.leaflet-map {
  position: relative;
  height: 100vh;
  width: 100%;
}

/* Responsive styles */
@media (max-width: 768px) {
  .back-button {
    position: relative;
    top: -52px;
    left: 0px;
    z-index: 9990;
  }
}

@media (max-width: 576px) {
  .back-button {
    position: relative;
    top: -52px;
    left: 0px;
    z-index: 9990;
  }
}
</style>
